/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://www.fontshare.com/"
  }, "Font Share")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://open-foundry.com/fonts"
  }, "Open Foundry")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://uncut.wtf/"
  }, "Uncut")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "http://www.love-letters.be/foundry.html"
  }, "Love Letters")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "http://velvetyne.fr/"
  }, "Velvetyne")), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "http://www.tunera.xyz/"
  }, "Tunera")), "\n", React.createElement(_components.h3, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://gitlab.com/raphaelbastide/libre-foundries"
  }, "Raphael Bastinde Font Foundry List")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
